<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>结算单审批</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="approval-detail-info">
            <div class="approval-detail-container">
                <div class="name-container">
          <span>{{
                  detail.true_name | sub_name
              }}</span>
                    <div class="state-container">
                        <div class="content">{{ detail.true_name
                            }}<i :class="{red:detail.shenpi_num>1}">(第{{ detail.shenpi_num }}次提交)</i></div>
                        <div class="state" v-if="detail.state == 1 &&  detail.confirm === 0">
                            等待{{ detail.true_name }}确认
                        </div>
                        <div class="state" v-else>
                            {{ detail.state | state
                            }}
                        </div>
                    </div>
                </div>
                <div class="approval-detail-title">
          <span class="approval-detail-tip">审批编号：</span
          ><span class="approval-detail-content">{{ detail.shenpi_no }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content">{{ detail.project.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
          <span class="approval-detail-tip">所属学会：</span
          ><span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
          <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.project.cid) }}：</span
          ><span class="approval-detail-content">{{
                        detail.project.name
                    }}</span>
                </div>
                <div class="approval-detail-title">
          <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.project.cid) }}：</span
          ><span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
          <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.project.cid) }}：</span
          ><span class="approval-detail-content">{{ detail.project.chairman }}</span>
                </div>
                <project-info :cid="detail.project.cid" :info="detail.project_attach"></project-info>
                <div class="approval-detail-title">
          <span class="approval-detail-tip">企业名称：</span
          ><span class="approval-detail-content">{{
                        detail.zhaoshang ? detail.zhaoshang.corporate_name : '无'
                    }}</span>
                </div>
                <div class="approval-detail-title">
          <span class="approval-detail-tip">联系人：</span
          ><span class="approval-detail-content">{{ detail.contacts }}</span>
                </div>
                <div class="approval-detail-title">
          <span class="approval-detail-tip red">备注：</span
          ><span class="approval-detail-content red">{{ detail.remark }}</span>
                </div>


                <div class="approval-detail-title">
                    <span class="approval-detail-tip">附件：</span>
                    <div class="approval-detail-file">
                        <a
                            target="_blank"
                            v-for="(file, index) in detail.files"
                            :href="file.filePath | filePath"
                            :key="index"
                        >{{ file.fileName }}</a
                        >
                    </div>
                </div>
            </div>
            <div class="approval-list-container">
                <div class="approval-list-tip">审批流程</div>
                <div class="approval-list">
                    <div class="name-container active">
                    <span>  {{detail.true_name | sub_name}} <i class="el-icon-success"></i></span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div class="info-time">{{ detail.add_time | formatTime }}</div>
                            </div>
                            <div class="info-desc">发起申请</div>
                        </div>
                    </div>
                    <template  v-for="(item,index) in detail.shenpi.slice(0, 2)">
                        <div :key="item.id"  class="name-container active"
                            v-if="index === 0 || detail.shenpi[index-1].state === 1">
                            <span>{{item.true_name | sub_name}}
                              <i :class="item.state === 0 || item.state === 3 ? 'el-icon-remove'
                                    : item.state === 1 ? 'el-icon-success': 'el-icon-error'"></i>
                            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        {{ item.true_name }}<i v-if="item.state != 0">({{ item.state | sp_state }})</i>
                                    </div>
                                    <div class="info-time" v-if="item.sp_time">
                                        {{ item.sp_time | formatTime }}
                                    </div>
                                </div>
                                <div class="info-desc" v-if="item.state === 0">
                                    {{ iself(item) ? "我（" : "" }}{{ item.state | sp_state }}{{ iself(item) ? "）" : "" }}
                                </div>
                                <div class="info-desc" v-if="item.state !== 0">
                                    {{ item.sp_remark }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="name-container active" v-if="detail.state == 1 || detail.state == 5">
                        <span>{{detail.true_name | sub_name }}
                          <i :class="detail.confirm === 0? 'el-icon-remove': 'el-icon-success'"></i>
                        </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div class="info-time" v-if="detail.allSp[2].sp_time">
                                    {{ detail.allSp[2].sp_time | formatTime }}
                                </div>
                            </div>
                            <div class="info-desc">
                                {{detail.confirm === 0 ? "确认中" : "确认知晓" }}
                            </div>
                        </div>
                    </div>
                    <div v-if="detail.state == 5 || (detail.state == 1 && detail.confirm ==1)">
                        <div class="name-container active" v-for="item in detail.shenpi.slice(2)" :key="item.id">
                            <span>{{item.true_name | sub_name }}
                              <i :class="item.state === 0? 'el-icon-remove': item.state === 1? 'el-icon-success': 'el-icon-error'"></i>
                            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        {{ item.true_name }}
                                    </div>
                                    <div class="info-time" v-if="item.sp_time">
                                        {{ item.sp_time | formatTime }}
                                    </div>
                                </div>
                                <div class="info-desc">
                                    {{item.state == 1 ? "已确认" : "用印确认中"}}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="name-container">
                        <span class="iconfont icontongzhi"></span>
                        <div class="info-container">
                            <div class="info-content">抄送人</div>
                            <div class="info-desc">抄送{{ detail.copy.length }}人</div>
                            <div class="copy-members">
                                <div class="copy-members-name"
                                    v-for="item in detail.copy" :key="item.id"  >
                                     <span>{{ item.true_name | sub_name }}</span>
                                    <div>{{ item.true_name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="approval-btn">
                <el-button
                    class="approval-btn-left"
                    v-if="member_type == 1 && (detail.state ==0 &&  detail.shenpi[0].state === 0)"
                    @click="toRevoke()">
                    撤回
                </el-button>
                <router-link :to="'/home/otherApproval?id=' + pid" v-if="member_type == 1 && detail.state == 3">
                    <el-button class="approval-btn-left">重新提交
                    </el-button>
                </router-link>
                <el-button
                    v-if="(detail.state == 1 && member_type == 1 && detail.confirm === 0) ||
                    (detail.state == 5 && member_type == 0) ||
                     (member_type == 0 && isApprovaler)" class="approval-btn-left"
                    @click="shenpi_rejectedVisible = true">驳回
                </el-button>
                <el-button class="approval-btn-right"
                           :loading="requestState" :disabled="requestState"
                           v-if="detail.state == 1 && member_type == 1 && detail.confirm === 0"
                           @click="contractConfirm"
                >已申请线下用印
                </el-button>
                <el-button
                    class="approval-btn-right"
                    :loading="requestState" :disabled="requestState"
                    v-if="detail.state == 5 && member_type == 0"
                    @click="approvalConfirm">已用印
                </el-button>
                <el-button class="approval-btn-left"
                           v-if="isApprovaler && member_type == 0 && detail.state == 0"
                           @click="showDialog(2)">拒绝
                </el-button>
                <el-button class="approval-btn-right"
                           v-if="isApprovaler && member_type == 0 && detail.state == 0"
                           @click="showDialog(1)">
                    通过
                </el-button>
            </div>
        </div>
        <div class="history-container" v-if="detail.history.length > 0">
            <div class="history-title">历史记录</div>
            <div class="history-list">
                <router-link
                    :to="'/home/otherApprovalDetail?cctype=1&id=' + item.id + '&type=' + member_type"
                    class="history-item"
                    v-for="(item, index) in detail.history"
                    :key="index">
                    <div class="item-content">{{ index + 1 }}.{{ item.desc }}</div>
                    <div class="item-time">{{ item.add_time }}</div>
                </router-link>
            </div>
        </div>
        <el-dialog
            :visible.sync="editVisible"
            width="30%"
            center
            :before-close="closeDialog"
        >
      <span slot="title" class="dialog-title">{{
              state === 1 ? "审批同意确认" : "审批拒绝原因"
          }}</span>
            <div class="dialog-container flex-center">
                <el-input
                    type="textarea"
                    resize="none"
                    :rows="5"
                    v-model="remark"
                    :placeholder="
            state === 1 ? '请输入审批同意意见' : '请输入审批拒绝原因'
          "
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toApproval()" :loading="requestState" :disabled="requestState">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog :before-close="closeDialog" :visible.sync="shenpi_rejectedVisible"
                   center width="30%">
            <span slot="title" class="dialog-title">审批驳回</span>
            <div class="dialog-container flex-center">
                <el-input v-model="remark" placeholder="请输入审批驳回原因"
                          :rows="5" resize="none" type="textarea"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button :disabled="requestState" :loading="requestState" type="primary"
                           @click="shenpi_rejected()">确定</el-button>
              </span>
        </el-dialog>
        <el-tooltip placement="top" effect="light">
            <div slot="content">
                <div v-for="(item,index) in detail.shenpi_rejected" :key="index">
                    {{ item.true_name }}{{ item.create_time | formatTime }}驳回了审批，驳回原因为：{{ item.message }}<br/>
                </div>
            </div>
            <div class="record flex align-center justify-center" v-if="detail.shenpi_rejected && detail.shenpi_rejected.length">
                驳回<br>记录
            </div>
        </el-tooltip>
    </div>
</template>

<script>
import {getPathIndex, sub_name, webUrl, ZHDate} from "../../tools";
import ProjectInfo from "../components/ProjectInfo";

export default {
    components: {ProjectInfo},
    name: "statementsDetail",
    data() {
        return {
            pid: "",
            member_type: -1,
            isApprovaler: false,
            editVisible: false,
            shenpi_rejectedVisible: false,
            requestState: false,
            state: -1,
            remark: "",
            detail: {},
        };
    },
    created() {
        this.$emit("setIndex", [0, getPathIndex(this.$route.query.type)]);
        this.init();
    },
    methods: {
        showDialog(type) {
            this.editVisible = true;
            this.state = type;
            this.remark = this.state === 1 ? "同意" : "";
        },
        closeDialog() {
            this.remark = ''
            this.shenpi_rejectedVisible = false;
            this.editVisible = false;
            this.state = -1;
        },
        toApproval() {
            if (!this.remark) {
                this.$message.error(
                    this.state === 1 ? "请输入审批同意意见" : "请输入审批拒绝原因"
                );
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .shenpi({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 27, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    state: this.state,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 27, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                            state: this.state,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        toRevoke() {
            this.$api.apiContent
                .revoke({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 27, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 27, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.backPage();
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        shenpi_rejected() {
            if (!this.remark) return this.$message.error("请输入驳回原因")
            this.requestState = true
            this.$api.apiContent
                .shenpi_rejected({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 27, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    state: 2,
                    id: this.pid,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 27, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            state: 2,
                            id: this.pid,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        approvalConfirm() {
            this.requestState = true
            this.$api.apiContent
                .shenpi({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 27, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    state: 1,
                    affirm: 1,//确认传参
                    remark: "同意",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 27, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                            state: 1,
                            affirm: 1,//确认传参
                            remark: "同意",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        contractConfirm() {
            this.requestState = true
            this.$api.apiContent
                .jieSuanDanConfirm({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        init() {
            this.detail = {}
            this.pid = this.$route.query.id;
            this.member_type = this.$route.query.type;
            this.$api.apiContent
                .getJieSuanDan({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: this.$route.query.cctype ? this.$route.query.cctype : "",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            type: this.$route.query.cctype ? this.$route.query.cctype : "",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.history.map((item) => {
                        item.desc = item.desc.replace(/<br>/g, " ");
                        item.add_time = this.$tools.ZHDate(
                            new Date(item.add_time * 1000),
                            1
                        );
                    });
                    res.data.allSp = res.data.shenpi
                    res.data.shenpi = res.data.shenpi.filter(item => {
                        return !item.is_user || item.is_user === 2
                    })
                    res.data.time =
                        this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) +
                        " 至 " +
                        this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                    this.detail = res.data;
                    this.isApproval();
                })
                .catch(() => {
                    this.detail = {};
                });
        },
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        isApproval: function () {
            this.shenpi_rejectedVisible = false;
            this.editVisible = false
            this.isApprovaler = false
            this.detail.shenpi.map((item, index) => {
                if (item.user_id === this.$store.state.user.mid && item.state === 0) {
                    if (index === 0) {
                        this.isApprovaler = this.detail.state === 0;
                    } else {
                        if (this.detail.shenpi[index - 1].state === 1) {
                            this.isApprovaler = this.detail.state === 0;
                        }
                    }
                }
            });
            let that = this
            setTimeout(function () {
                that.requestState = false
            }, 1000)
        },
        backPage() {
            this.$tools.goBack();
        },
    },
    filters: {
        filePath(url) {
            return webUrl(url)
        },
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "审批中";
                case 1:
                    return "已完成";
                case 2:
                    return "已归档";
                case 3:
                    return "已驳回";
                case 4:
                    return "已撤销";
                case 5:
                    return "等待用印登记";
                default:
                    return "已撤销";
            }
        },
        sp_state(type) {
            switch (Number(type)) {
                case 0:
                    return "审批中";
                case 3:
                    return "未审批";
                case 1:
                    return "已通过";
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
    computed: {
        isSend: function () {
            return this.detail.user_id === this.$store.state.user.mid;
        },
    },
    watch: {
        $route: "init",
    },
};
</script>

<style scoped lang="scss">
.page-box {
    .dialog-title {
        color: #000;
        font-size: 24px;
    }

    .dialog-footer {
        .el-button {
            width: 235px;
        }
    }

    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .approval-detail-info {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        min-height: 600px;
        padding-bottom: 100px;

        .approval-btn {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 50%;
            position: absolute;
            bottom: 60px;
            padding-left: 120px;

            .approval-btn-left {
                width: 180px;
                background: transparent;
                border: 1px solid #3b77e7;
                color: #3b77e7;
            }

            .approval-btn-right {
                width: 180px;
                margin-left: 20px;
                background: #3b77e7;
                color: #ffffff;
            }
        }

        .approval-list-container {
            display: flex;
            flex-direction: row;
            padding: 40px 80px;

            .approval-list {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                .name-container {
                    position: relative;
                    flex-grow: 1;
                    display: flex;
                    padding-bottom: 50px;
                    flex-direction: row;

                    &.active {
                        &:before {
                            position: absolute;
                            background: #d3d4d6;
                            width: 1px;
                            top: 0px;
                            bottom: 0px;
                            left: 28px;
                            content: "";
                        }
                    }

                    .info-container {
                        flex-grow: 1;
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .info-top {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .info-content {
                                font-size: 20px;
                                color: #333333;

                                i {
                                    font-size: 14px;
                                }
                            }

                            .info-time {
                                font-size: 16px;
                                color: #999999;
                            }
                        }

                        .info-desc {
                            margin-top: 2px;
                            font-size: 16px;
                            color: #999999;

                            .upfile-list {
                                width: 400px;

                                li {
                                    height: 31px;
                                    border-bottom: 1px dashed #ccc;

                                    a {
                                        display: block;
                                        width: 350px;
                                        height: 30px;
                                        line-height: 30px;
                                        color: #3b77e7;
                                    }

                                    .el-icon-delete {
                                        font-size: 16px;
                                        cursor: pointer;

                                        &:hover {
                                            color: #3b77e7;
                                        }
                                    }
                                }
                            }

                            .info-file {
                                color: #3b77e7;
                                margin-top: 12px;
                            }

                            .info-file-up {
                                position: relative;
                                width: 250px;
                                background: transparent;
                                border: 1px solid #3b77e7;
                                font-size: 12px;
                                margin-top: 12px;
                                padding-left: 50px;
                                color: #3b77e7;

                                .info-file-up-logo {
                                    position: absolute;
                                    top: 25%;
                                    left: 20px;
                                }
                            }
                        }

                        .copy-members {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 20px;

                            .copy-members-name {
                                margin-right: 10px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        border: 1px solid #3b77e7;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 16px;
                        color: #ffffff;
                        background-color: #3b77e7;
                        position: relative;

                        i {
                            position: absolute;
                            bottom: -5px;
                            right: -5px;
                            font-size: 18px;
                            width: 18px;
                            height: 18px;
                            overflow: hidden;

                            &.el-icon-remove {
                                color: #ee9900;
                            }

                            &.el-icon-success {
                                color: #13ce66;
                            }

                            &.el-icon-error {
                                color: #ff0000;
                            }
                        }
                    }
                }
            }

            .approval-list-tip {
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
                margin-top: 12px;
            }
        }

        .approval-detail-container {
            padding: 40px;
            border-bottom: 1px solid #f0f2f5;

            .approval-detail-title {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                color: #999999;
                width: 100%;

                .approval-detail-file {
                    flex: 7;
                    flex-shrink: 1;
                    display: flex;
                    flex-direction: column;

                    a {
                        font-size: 18px;
                        cursor: pointer;
                        margin-bottom: 8px;
                        display: block;
                        color: #3b77e7;

                        &:hover {
                            font-size: 18px;
                        }
                    }
                }

                .approval-detail-tip {
                    flex: 1;
                    flex-shrink: 0;
                    text-align: right;

                    &.red {
                        color: red;
                    }
                }

                .approval-detail-tip-length {
                    flex-shrink: 0;

                    &.red {
                        color: red;
                    }
                }

                .approval-detail-content {
                    flex: 7;
                    flex-shrink: 1;
                    color: #333333;

                    &.red {
                        color: red;
                    }
                }
            }

            .name-container {
                display: flex;
                flex-direction: row;
                padding-left: 40px;

                .state-container {
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .content {
                        font-size: 22px;
                        color: #333333;

                        .red {
                            color: red;
                        }
                    }

                    .state {
                        font-size: 16px;
                        margin-top: 6px;
                        color: #13ce66;
                    }
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 65px;
                    height: 65px;
                    border-radius: 65px;
                    text-align: center;
                    cursor: pointer;
                    /*line-height: 24px;*/
                    font-size: 20px;
                    color: #fff;
                    background-color: #3b77e7;
                }
            }
        }
    }
}
</style>
